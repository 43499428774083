<template>
  <v-container>
    <v-row align="center">
      <v-col cols="6" md="3" class="ma-0 pa-0">
        <v-card>
          <v-card-title>
            <p class="dispay-1 text--primary">仮想会議情報</p>
          </v-card-title>
          <v-card-text>
            <div>会議室ID： {{ meetingID }}</div>
            <div>表示名： {{ name }}</div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6" md="3">
        <v-btn class="ma-1" outlined :disabled="disabledStartBtn" @click="startWebcam">カメラ起動</v-btn>
        <v-btn class="ma-1" outlined :disabled="disabledStopBtn" @click="stopWebcam">カメラ停止</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'ControlPanel',

    components: {
    },

    data: () => ({
      //
      unwatch: null,  // for vuex
      dialog: false,
      disabledStartBtn: true,
      disabledStopBtn: true,
      //
      meetingID: '',
      passPhrase: '',
      name: ''
    }),
    //
    mounted () {
      //
      this.unwatch = this.$store.watch(
        (state, getters) => getters['Application/getLoadedModel'], (newv) => {
          if(newv) {
            this.disabledStartBtn = false
            this.disabledStopBtn = false
          }
        }
      )
      // check loaded model
      if(this.$store.getters['Application/getLoadedModel']) {
            this.disabledStartBtn = false
            this.disabledStopBtn = false
      }
      //
      this.meetingID = this.$store.getters['Meeting/getMeetingID']
      this.name = this.$store.getters['Meeting/getName']
    },
    // life cycle
    beforeDestroy() {
      this.unwatch = null
    },
    // watch & computed
    computed: {
      masked_passphrase () {
        return "*".repeat(this.passPhrase.length)
      }
    },

    methods: {
      startWebcam(){
        this.$store.dispatch('Application/setStartedWebcam', true)
        this.disabledStartBtn = true
        this.disabledStopBtn = false
      },
      stopWebcam() {
        this.$store.dispatch('Application/setStartedWebcam', false)
        this.disabledStopBtn = true
        this.disabledStartBtn = false
      },
      updateMeetingInfo(){
        this.meetingID = this.$store.getters['Meeting/getMeetingID']
        this.passPhrase = this.$store.getters['Meeting/getPassPhrase']
        this.name = this.$store.getters['Meeting/getName']
      }
    }
  }
</script>

<style>
</style>