import * as ks4c from '@/Libs/ks4c.js'
import * as AWS from 'aws-sdk'

export default {
  data: () => ({
    //
    meeting: {},
    state: {},
    machineInfo: null,
    connStatus: null,
    connStatusString: '',
    advertised: false,
    advertisedUID: null,
    $_unwatch_join: null,
    $_mian_timer: null,
  }),
  //
  mounted () {
    this.popState()
    // FIXME: rewrite here
    this.prepearingMachineInfo()
    this.machineInfo = this.$store.getters['Application/getMachineInfo']
    if(!this.machineInfo) {
      console.error('Cant get machineinfo')
      this.machineInfo = ks4c.generatePseudoUuid()
    }
  },
  beforeDestroy() {
    this.$_unwatch_join = null
  },
  //
  computed: {
    statusString () {
      return this.connStatusString
    }
  },
  //
  methods: {
    // DynamoDB
    //
    dynamoDbFailedGetMemberNames (err) {
      this.$store.dispath('Meeting/setJoinStatus', 0)
      console.log("dynamodb error: getMemberNames", err, err.stack);
      alert("サーバから情報を取得できませんでした。\ndynamodb error:" + err);
    },
    //
    dynamoDbSuccessGetMemberNames (data) {
//      console.log("dynamodb success: getMemberNames", data)

      const members = this.meeting['members'];
      for (let i = 0; i < data.Items.length; i++) {
          const item = data.Items[i];
          const id = item.UserId.N;
          if (!members[id]) { continue; }

          members[id].name = item.Name.S;
          members[id].organizer = item.IsOrganizer.S == 'Y';
      }

      this.showCurrentStatus()
    },
    //
    readDynamoDb () {
      const epochTime = this.meeting['lastRetrieved']; // unix time
      const ddbParams = {
        TableName: this.meeting['RealtimeInfoTable'],
        ScanIndexForward: false,
        Limit: ks4c.MeetingInfo.MinRetrivalCount,
        KeyConditionExpression: "Room = :v_room AND CreatedDate > :v_date",
        ExpressionAttributeValues: { ":v_room": { S: ks4c.MeetingInfo.DummyRoom }, ":v_date": { S: "" + epochTime } }
      }
      const _self = this
      this.meeting['ddb'].query(ddbParams, function (err, data) {
        if (err) { _self.dynamoDbFailed(err); } // an error occurred
        else { _self.dynamoDbSuccess(data); }   // successful response
      });
    },
    //
    dynamoDbFailed (err) {
      // meeting terminated -> ResourceNotFoundException: Requested resource not found
      console.log("dynamodb error", err, err.stack);
      this.$store.dispath('Meeting/setJoinStatus', 0)
      alert("サーバへの接続に失敗しました。\ndynamodb error:" + err);
    },
    // getting comment from a server
    getMemberComment(commentStr) {
      const cmt_len = commentStr.length
      let currPos = 0
      let comments = {}

      while(currPos < cmt_len) {
        const cmt_uid = Number(commentStr.slice(currPos, currPos + 4))
        const cmt_sec = Number(commentStr.slice(currPos + 4, currPos + 7))
        const cmt_len = Number(commentStr.slice(currPos + 7, currPos + 9))
        const cmt_str = commentStr.slice(currPos + 9, currPos + 9 + cmt_len)
        currPos = currPos + 9 + cmt_len
        // TODO: need to check comment length
        comments[cmt_uid] = {'uid': cmt_uid, 'sec': cmt_sec, 'message': cmt_str }
      }

      return comments
    },
    //
    dynamoDbSuccess (data) {
      console.log("dynamodb success", data)

      const currMembers = {};
      for (let i = 0; i < data.Items.length; i++) {
        const item = data.Items[i]
        const ts = parseInt(item.CreatedDate.S)
        if (ts > this.meeting['lastRetrieved']) {
          this.meeting['lastRetrieved'] = ts
        }
        if (!item.State) { continue; }

        // TODO: handle comment
        const cmt = item.Comment.S
        if(cmt != ''){
          let comments = this.getMemberComment(cmt)
          let mbcmt = this.$_copyObject(this.$store.getters['Meeting/getMemberComments'])
          for(let uid in comments) {
            if(uid in mbcmt) {
              if(comments[uid].message != '') {
                mbcmt[uid] = comments[uid]
              } else {
                // TODO: neeed to implement if comment time is over
              }
            } else {
              mbcmt[uid] = comments[uid]
            }
          }
//          console.log(mbcmt)
          this.$store.dispatch('Meeting/setMemberComments', mbcmt)
        }
        //
        const statusString = item.State.S
        const numOfMembers = statusString.length / ks4c.MeetingInfo.CurrentStateStringLength
        for (let j = 0; j < numOfMembers; j++) {
          const str = statusString.substr(j * ks4c.MeetingInfo.CurrentStateStringLength, 10)
          //console.log(str)
          // parse state string
          if (str.length < 10) { continue; }

          // abbbbccdef:     a: data version, b: user id, c: live value, d: face direction, e: gesture, f: dominant emotion
          const version = ks4c.fromHex1(str.substr(0, 1));
          if (version > ks4c.MeetingInfo.DataVersion) { continue; }

          const id = ks4c.fromHex4(str.substr(1, 4));
          if (id == null) {
            console.log(`Invalid id: ${statusString}`)
            continue
          }
/*          if(member_ids.includes(id)) {
            console.log(`Duplicated ID: ${id}`)
            continue
          } */

          const value = ks4c.fromHex2(str.substr(5, 2));
          const fd = ks4c.fromHex1(str.substr(7, 1));
          const ges = ks4c.fromHex1(str.substr(8, 1));
          const emo = ks4c.fromHex1(str.substr(9, 1));
          const mem = {
            smile: value != null && (value & 1) != 0,
            attention: value != null && (value & 2) != 0,
            reaction: value != null && (value & 4) != 0,
            nod: value != null && (value & 8) != 0,
            faceDirection: fd, gesture: ges, emotion: emo
          }
          currMembers[id] = mem

          // TODO handle advertise
          this.advertised = (value != null && (value & 80) != 0)
//          console.log(this.advertised)
          if(this.advertised) {
            this.advertisedUID = id
          }
        }
      }
//      console.log('Finishing current members')
      //console.log(currMembers)
      // check banned
      if(this.advertised && (this.advertisedUID == this.$store.getters['Meeting/getUserID'])) {
        this.exitMeeting()
      }

      // update or add new member
//      console.log(this.meeting)
      const now = this.meeting['lastRetrieved']
      const members = this.meeting['members']
      const newMemberIds = [];
      Object.keys(currMembers).forEach(id => {
        const curr = currMembers[id];
        if (members[id]) {  // exists: copy attrs
          const member = members[id];
          member.smile = curr.smile;
          member.attention = curr.attention;
          member.reaction = curr.reaction;
          member.nod = curr.nod;
          member.faceDirection = curr.faceDirection;
          member.gesture = curr.gesture;
          member.emotion = curr.emotion;
          member.timestamp = now;
        } else {                // new
          curr.name = "U" + id; // dummy
          curr.organizer = false;
          curr.timestamp = now;
          members[id] = curr;
          newMemberIds.push(id);
        }
      })

      // remove older members
      Object.keys(members).forEach(id => {
        const member = members[id];
        if (member.timestamp + ks4c.MeetingInfo.NoUpdateMemberHoldingMillis < now) {
          delete members[id]
        }
      })

      // get name for new member
      if (newMemberIds.length > 0) {
          let minId = newMemberIds[0];
          for (let i = 1; i < newMemberIds.length; i++) {
            minId = Math.min(minId, newMemberIds[i]);
          }

          const ddbParams = {
            TableName: this.meeting['UserManagerTable'],
            ScanIndexForward: false,
            KeyConditionExpression: "MeetingUuid = :v_uuid AND UserId > :v_userid",
            ExpressionAttributeValues: { ":v_uuid": { S: this.meeting['MeetingUuid'] }, ":v_userid": { N: "" + (minId - 1) } }
          };
          const _self = this
          this.meeting['ddb'].query(ddbParams, function (err, data) {
            if (err) { _self.dynamoDbFailedGetMemberNames(err); } // an error occurred
            else { _self.dynamoDbSuccessGetMemberNames(data); }   // successful response
          });
          return;
      }
      //console.log('Finish Building members')
      this.showCurrentStatus();
    },
    //
    showCurrentStatus () {
      // TODO escape values
      //      let statusHtml = "";
      const members = this.meeting['members']
      const _self = this
      //
      let mm = this.$_copyObject(members)
//      console.log(mm)
      this.$store.dispatch('Meeting/setMeetingState', mm)
      this.$store.dispatch('Meeting/updateLastNsState', mm)
      if(this.$store.getters['Meeting/getMeasuringState'] == 1) {
        this.$store.dispatch('Meeting/updateStoredState', mm)
      }
      // process comment
      {
        const currCmt = this.$store.getters['Meeting/getMemberComments']
//        console.log('In_show_currentStatus')
//        console.log(currCmt)
        let nextCmt = this.$_copyObject(currCmt)
        for(let uid in nextCmt) {
          if(nextCmt[uid].sec <= 1) {
            nextCmt[uid].sec = 0
            nextCmt[uid].message = ''
          } else {
            nextCmt[uid].sec = nextCmt[uid].sec - 1
          }
        }
        this.$store.dispatch('Meeting/setMemberComments', nextCmt)
      }
      this.connStatusString = ""
      Object.keys(members).forEach(id => {
        const member = members[id];
        _self.connStatusString += "<p>" + member.name + "(" + id + "): [" +
          (member.smile ? ks4c.Emojis.SmileEmoji : "　") +
          (member.attention ? ks4c.Emojis.AttentionEmoji : "　") +
          (member.reaction ? ks4c.Emojis.ReactionEmoji : "　") +
          (member.nod ? ks4c.Emojis.NodEmoji : "　") +
          ((member.gesture != null && member.gesture >= 0) ? ks4c.Emojis.GestureEmojis[member.gesture] : "　") + "]" +
          "fd:" + member.faceDirection + "," +
          "em:" + member.emotion +
          "</p>";
      });

      // setup next main loop
      this.$_mian_timer = setTimeout(this.mainLoop, 1000);
    },
    // Kinesis
    //
    kinesisFailed (err) {
      console.log("kinesis error", err, err.stack)
      alert("サーバへの接続に失敗しました。\nKinesis error:" + err)
      this.$store.dispath('Meeting/setJoinStatus', 0)
    },
    kinesisSuccess (data) {
      console.log("kinesis success", data)
      this.readDynamoDb()
    },
    // popState
    popState () {
      const st = this.$store.getters['Meeting/getUserState']
      let sst = { smile: false, smileValue: 0, reaction: false, reactionValue: 0, gesture: null, nod: false, attention: false }
      this.$store.dispatch('Meeting/setUserState', sst)
      return st
    },
    // mainloop
    mainLoop () {
      // console.log("Looping...");
      if (!this.meeting['MeetingId']) { return; }

      const st = this.popState()
      const faceDirection = 1
      const gesture = st.gesture
      const emotion = st.smile ? ks4c.Emojis.EmotionJoy : (st.reaction ? ks4c.Emojis.EmotionSurprise : 0)
      const roll = 0
      const pitch = 0
      const yaw = 0

      // state
      // TODO advertise after banned
      const bits = (st.smile ? 1 : 0) + (st.attention ? 2 : 0) + (st.reaction ? 4 : 0) + (st.nod ? 8 : 0);
      const upState = ks4c.toHex1(ks4c.MeetingInfo.DataVersion) + ks4c.toHex4(this.meeting['UserId']) + ks4c.toHex2(bits) + ks4c.toHex1(faceDirection) + ks4c.toHex1(gesture) + ks4c.toHex1(emotion);

      // metric
      const vals = [];
      for (let i = 0; i < 13; i++) {
          if (i == 4) { vals.push(st.smileValue * 100); }
          else if (i == 6) { vals.push(st.reactionValue * 100); }
          else { vals.push(0); }
      }
      vals.push(roll);
      vals.push(pitch);
      vals.push(yaw);

      let upMetrics = ks4c.toHex1(ks4c.MeetingInfo.DataVersion);
      for (let i = 0; i < vals.length; i++) {
          upMetrics += ks4c.toHexSigned2(vals[i]);
      }
      upMetrics += (this.meeting['MeetingId'] + '          ').slice(10);

      // put to kinesis
      let params = { 'MeetingId': this.meeting['MeetingId'], 'State': upState, 'Metrics': upMetrics, 'Room': ks4c.MeetingInfo.DummyRoom }
      // process comment
      const currCmt = this.$store.getters['Meeting/getComment']
//      console.log('Comment_IN_MainLoop')
//      console.log(currCmt)
      if( currCmt != '') {
        const cmtSec = ('000' + 10).slice(-3) // FIXME:
        const cmtParam = `${cmtSec}${currCmt}`
        params['Comment'] = cmtParam
        this.$store.dispatch('Meeting/setComment', '')
      }
      const jsonStr = JSON.stringify(params)
      // console.log(jsonStr);

      const kinesisParams = { StreamName: this.meeting['StreamName'], Data: jsonStr, PartitionKey: this.meeting['PartitionKey'] }
      const _self = this
      this.meeting['kinesis'].putRecord(kinesisParams, function (err, data) {
          if (err) { _self.kinesisFailed(err); } // an error occurred
          else { _self.kinesisSuccess(data); }           // successful response
      });
    },
    //
    startMainLoop () {
      setTimeout(this.mainLoop, 1000);
    },
    //
    async callConnect () {
      const meetingId = this.$store.getters['Meeting/getMeetingID']
      const pass = this.$store.getters['Meeting/getPassPhrase']

      const params = { "MeetingId": meetingId, "PassPhrase": pass };
      const url = `https://${ks4c.MeetingInfo.ConnApiId}.execute-api.ap-northeast-1.amazonaws.com/${ks4c.MeetingInfo.ConnStageName}/participant/connect?` +
          new URLSearchParams(params).toString();

      const _self = this
      await fetch(url, { mode: 'cors' }).then(function (response) {
        return response.json()
      }).then(function (json) {
        const errCode = json['ErrorCode']
        const errMsg = json['ErrorMessage']
        if(errCode == "E002"){
          alert("パスフレーズが異なります。\nAPI Error:" + errCode + "(" + errMsg + ")")
          this.$store.dispath('Meeting/setJoinStatus', 0)
          return;
        } else if (errCode) {
          alert("サーバとの接続に失敗しました。\nAPI Error:" + errCode + "(" + errMsg + ")")
          this.$store.dispath('Meeting/setJoinStatus', 0)
          return;
        }

        if (!ks4c.getParamsFromJson(json, _self.meeting, ['ApiId', 'StageName'])) {
          alert("サーバとの接続に失敗しました。ページをリロードして、接続し直してください。\nInvalid server response.");
          return;
        }
        console.log("ApiId:" + _self.meeting['ApiId'] + ",StageName:" + _self.meeting['StageName'])

        return _self.callJoinMeeting()
      }).catch(function (err) {
        console.log('Fetch problem: ' + err.message + '\n' + err);
        alert("サーバとの接続に失敗しました。ページをリロードして、接続し直してください。\nError on connect:" + err.message);
      })
    },
    //
    async callJoinMeeting () {
      const meetingId = this.$store.getters['Meeting/getMeetingID']
      const pass = this.$store.getters['Meeting/getPassPhrase']
      const nameOnMeeting = this.$store.getters['Meeting/getName']

      const params = { "MeetingId": meetingId, "PassPhrase": pass, "Name": nameOnMeeting, "MachineInfo": this.machineInfo, "Room": ks4c.MeetingInfo.DummyRoom };
      const url = `https://${this.meeting['ApiId']}.execute-api.${ks4c.MeetingInfo.AwsRegion}.amazonaws.com/${this.meeting['StageName']}/participant/start?` +
          new URLSearchParams(params).toString();

      const _self = this
      const meeting = this.meeting
      await fetch(url, { mode: 'cors' }).then(function (response) {
        return response.json();
      }).then(function (json) {
        const errCode = json['ErrorCode'];
        const errMsg = json['ErrorMessage'];
        if (errCode) {
          this.$store.dispath('Meeting/setJoinStatus', 0)
          alert("サーバとの接続に失敗しました。\nAPI Error:" + errCode + "(" + errMsg + ")");
          return;
        }

        const params = ['AccessKeyId', 'SecretAccessKey', 'SessionToken', 'Expiration', 'UserId', 'ServerTime', 'MeetingUuid', 'RealtimeInfoTable',
          'UserManagerTable', 'StreamName', 'PartitionKey'];
        if (!ks4c.getParamsFromJson(json, meeting, params)) {
          this.$store.dispath('Meeting/setJoinStatus', 0)
          alert("サーバから正しいデータを受け取れませんでした。。\nInvalid server response.")
          return
        }

        AWS.config.update({ region: ks4c.MeetingInfo.AwsRegion });
        meeting['kinesis'] = new AWS.Kinesis({
          apiVersion: '2013-12-02',
          accessKeyId: meeting['AccessKeyId'], secretAccessKey: meeting['SecretAccessKey'], sessionToken: meeting['SessionToken']
        });
        meeting['ddb'] = new AWS.DynamoDB({
          apiVersion: '2012-08-10',
          accessKeyId: meeting['AccessKeyId'], secretAccessKey: meeting['SecretAccessKey'], sessionToken: meeting['SessionToken']
        });
        meeting['AccessKeyId'] = null;
        meeting['SecretAccessKey'] = null;
        meeting['SessionToken'] = null;

        // yyyy-MM-dd HH:mm:ss
        meeting['MeetingId'] = meetingId;
        meeting['lastRetrieved'] = 0;
        meeting['members'] = {};

        // setUserID
        _self.$store.dispatch('Meeting/setUserID', meeting['UserId'])
        _self.startMainLoop(meeting)

        _self.$store.dispatch('Meeting/setJoinStatus', 2)
        alert("仮想会議に参加しました。")
      }).catch(function (err) {
        console.log('Fetch problem: ' + err.message + '\n' + err);
        this.$store.dispath('Meeting/setJoinStatus', 0)
        alert("仮想会議の参加に失敗しました。:" + err.message);
      });
    },
    //
    joinMeeting () {
      //
      this.callConnect()
    },
    //
    async exitMeeting() {
      const meeting = this.meeting
      if (!meeting['MeetingId']) {
        this.$store.dispath('Meeting/setJoinStatus', 0)
        alert("仮想会議に参加していません。");
        return;
      }

      //
      this.$store.dispatch('Application/setStartedWebcam', false)
      // "https://{0}.execute-api.{1}.amazonaws.com/{2}/common/exit";
      const params = { "MeetingId": meeting['MeetingId'], "UserId": meeting['UserId'] };
      const url = `https://${meeting['ApiId']}.execute-api.${ks4c.MeetingInfo.AwsRegion}.amazonaws.com/${meeting['StageName']}/common/exit?` +
        new URLSearchParams(params).toString();
      const _self = this
      await fetch(url, { mode: 'cors' }).then(function (response) {
        return response.json();
      }).then(function (json) {
        const errCode = json['ErrorCode'];
        const errMsg = "仮想会議退出に失敗しました。\n" + json['ErrorMessage'];
        if (errCode) {
          alert("API Error:" + errCode + "(" + errMsg + ")");
          return;
        }

        meeting['MeetingId'] = null
        _self.$store.dispatch('Meeting/setMeetingState', null)
        _self.$_mian_timer = null

        console.log("Response Json:" + JSON.stringify(json))
        alert("仮想会議を退出しました。")
      }).catch(function (err) {
        console.log('Fetch problem: ' + err.message + '\n' + err)
        alert("Error on exit:" + err.message);
      });
    },
    prepearingMachineInfo(){
      let mi = this.$ls.get('ks4c-machineinfo', null)
      if (!mi) {
        mi = ks4c.generatePseudoUuid()
        this.$ls.set('ks4c-machineinfo', mi)
      }
      this.$store.dispatch('Application/setMachineInfo', mi)
    },
    //
    $_copyObject(obj) {
      let tmp = JSON.stringify(obj)
      return JSON.parse(tmp)
    }
  }
}
