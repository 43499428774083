const state = {
  nodHistory: [],
  faceState: null, // {face: _face_, orientation: _orientaion_}
  nodHistorySize: 12,
}

const getters = {
  getNodHistory (state) {
    return state.nodHistory
  },
  getFaceState (state) {
    return state.faceState
  }
}

const mutations = {
  setNodHistory (state, pyld) {
    if(state.nodHistory.length == state.nodHistorySize) {
      state.nodHistory.shift()
    }
    state.nodHistory.push(pyld)
  },
  setFaceState (state, pyld) {
    state.faceState = pyld
  }
}

const actions = {
  setNodHistory ({ commit }, pyld) {
    commit('setNodHistory', pyld)
  },
  setFaceState ({ commit }, pyld) {
    commit('setFaceState', pyld)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}