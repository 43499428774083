<template>
  <v-container>
    <v-row class="frame">
      <v-expansion-panels v-model="open_panels">
        <v-expansion-panel>
          <v-expansion-panel-header color="blue lighten-5">
            <span style="font-weight: bold">ジェスチャー集計</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="panel-content" eager>
            <v-row dense class="ma-0 pa-0">
              <v-col cols="1">
                <v-btn outlined :color="summary_btn_color" @click="view_type='summary'" class="pa-0 text-caption">サマリー</v-btn>
              </v-col>
              <v-col cols="1" offset="3">
                <v-btn outlined :color="member_btn_color" @click="view_type='member'" class="pa-0 text-caption">メンバー別</v-btn>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="1">
                <v-btn outlined @click="start_aggrigating" class="pa-0 text-caption">集計開始</v-btn>
              </v-col>
              <v-col cols="1" offset="2">
                <v-btn outlined @click="pause_aggrigating" class="pa-0 text-caption">ポーズ</v-btn>
              </v-col>
              <v-col cols="1" offset="2">
                <v-btn outlined @click="stop_aggrigating" class="pa-0 text-caption">集計終了</v-btn>
              </v-col>
            </v-row>
            <v-row>
              <GSummary v-if="view_type=='summary'"></GSummary>
              <GMember v-if="view_type=='member'"></GMember>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </v-container>
</template>

<script>
  import GSummary from '@/components/GSummary.vue'
  import GMember from '@/components/GMember.vue'

export default {
  name: 'gesture_view',
  components: {
    GSummary, GMember,
  },
  //
  data: () => ({
    view_type: 'summary', // 'member'
    open_panels: 0,
  }),
  //
  computed: {
    summary_btn_color () {
      return (this.view_type == 'summary') ? "#90ee90" : ""
    },
    member_btn_color () {
      return (this.view_type == 'member') ? "#90ee90" : ""
    }
  },
  //
  methods: {
    start_aggrigating() {
      console.log('start aggrigating')
      this.$store.dispatch('Meeting/setMeasuringState', 1)
    },
    stop_aggrigating() {
      console.log('stop aggrigating')
      this.$store.dispatch('Meeting/setMeasuringState', 0)
    },
    pause_aggrigating() {
      console.log('pause aggrigating')
      this.$store.dispatch('Meeting/setMeasuringState', 2)
      this.$store.dispatch('Meeting/setStoredState', [])
    }
  },
}
</script>

<style scoped>
</style>