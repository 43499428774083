<template>
  <v-container>
    <v-row v-if="exited">
      <LoginPanel></LoginPanel>
    </v-row>
    <template v-if="logined">
      <v-row dense>
        <v-col cols="6"><v-img src="/images/ks4c-logo.png" width="25em" contain></v-img></v-col>
        <v-col cols="6" align-self="center"><v-btn outlined style="font: bold;" @click="exit_meeting()">仮想会議から退室</v-btn></v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <ControlPanel />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" md="6">
          <CameraPanel />
        </v-col>
        <v-col cols="12" md="6">
          <MemberView />
        </v-col>
        <v-col cols="12" md="6">
          <LiveSummary />
        </v-col>
        <v-col cols="12" md="6">
          <GestureView />
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
  import * as tf from '@tensorflow/tfjs'
  import '@tensorflow/tfjs-backend-webgl'

  import * as blazeface from '@tensorflow-models/blazeface'

//  import * as ks4c from '@/Libs/ks4c.js'

  import MeetingMixins from '@/Libs/mixins/MeetingMixins.js'

  import CameraPanel from '@/components/CameraPanel.vue'
  import ControlPanel from '@/components/ControlPanel.vue'
  import LoginPanel from '@/components/LoginPanel.vue'
  import MemberView from '@/components/MemberView.vue'
  import GestureView from '@/components/GestureView.vue'
  import LiveSummary from '@/components/LiveSummary.vue'

  export default {
    name: 'MainPanel',

    mixins: [MeetingMixins],

    components: {
      CameraPanel,
      ControlPanel,
      LoginPanel,
      MemberView,
      GestureView,
      LiveSummary,
    },

    data: () => ({
      //
      unwatch_join: null,
      joined: false,
      posAry: [],
      debug: false, // WARNING: should check when release
    }),
    computed: {
      logined() {
        if(this.debug){
          return true
        } else {
          return this.joined
        }
      },
      exited() {
        return !this.joined
      }
    },
    //
    mounted () {
      //
      this.setup_face_detection()
      // regist watcher
      this.unwatch_join = this.$store.watch(
        (state, getters) => getters['Meeting/getJoinStatus'], (newv, oldv) => {
          if(newv == 0) {
            this.joined = false
          } else if(newv == 1) {
            console.log('Keep joining')
            this.joined = false
            if(oldv != 1) {
              this.joinMeeting()
            }
          } else if(newv == 2) {
            this.joined = true
            console.log('Success to join')
          } else {
            this.joined = false
            console.log('Invalid Join Status')
          }
        }
      )

    },
    beforeDestroy() {
      this.unwatch_join = null
    },
    //
    methods: {
      // FIXME
      async setup_face_detection(){
        let tfBackend = 'webgl'
        await tf.setBackend(tfBackend)

        let blzfcModel = await blazeface.load()
        if (!blzfcModel) {
          console.log('Fail to load TF Model')
          return
        }
        this.$store.dispatch('Application/setBlzfcModel', blzfcModel)

        let fcemoModel = null
        let gestureModel = null
        fcemoModel = await tf.loadLayersModel('models/smile_reaction_model_ver2/model.json')
        gestureModel = await tf.loadLayersModel('models/gesture_201019_tfjs/model.json')
        this.$store.dispatch('Application/setFcemoModel', fcemoModel)
        this.$store.dispatch('Application/setGestureModel', gestureModel)
        this.$store.dispatch('Application/setLoadedModel', true)
      },
      exit_meeting() {
        this.exitMeeting()

        // set Status
        this.$store.dispatch('Meeting/setJoinStatus', 0)
      }
    }
  }
</script>

<style scoped>

</style>