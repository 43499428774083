<template>
  <v-container>
    <v-row v-for="(item, idx) in gesture_state" :key="idx">
      <v-col><span style="font-size: x-large">{{ markers[idx] }}</span></v-col>
      <v-col>{{ item }}</v-col>
    </v-row>
  </v-container>
</template>

<script>
import * as ks4c from '@/Libs/ks4c.js'

export default {
  name: 'g_summary',
  data() {
    return {
      reactions: ['smile', 'attention', 'reaction', 'nod', 'like'],
      markers: ks4c.Emojis.GestureEmojis,
      unwatchNsState: null,
      unwatchStoredState: null,
      currGestureState: {},
      img_path: [],
    }
  },
  //
  mounted() {
    const _self = this
    // watch state
    this.unwatchNsState = this.$store.watch(
      (state, getters) => getters['Meeting/getLastNsState'], (newv) => {
//        console.log(newv)
        _self.aggrigate_gesture()
      }
    )
    this.unwatchStoredState = this.$store.watch(
      (state, getters) => getters['Meeting/getStoredState'], (newv) => {
//        console.log(newv)
        _self.aggrigate_gesture()
      }
    )

    // initialize image path
    for(let idx = 0; idx < this.reactions.length - 1; idx++){
      this.img_path[idx] = '/images/reactions/' + ks4c.ReactionIMG[this.reactions[idx]]
    }
  },
  //
  beforeDestroy() {
    this.unwatchNsState = null
    this.unwatchStoredState = null
  },
  //
  computed: {
    gesture_state () {
//      console.log(this.currGestureState)
      return this.currGestureState
    }
  },
  //
  methods: {
    // FIXME: 
    aggrigate_gesture () {
      const measuring = this.$store.getters['Meeting/getMeasuringState']
      let state = null
      if(measuring == 1) {
        state = this.$store.getters['Meeting/getStoredState']
      } else if(measuring == 2) {
        return
      } else {
        state = this.$store.getters['Meeting/getLastNsState']
      }
      //console.log(state)
      let user_gesture = {} // userID:  gesture_id
      //
      for(let itm of state) {
        if(!itm) {
          continue
        }
        //console.log(itm)
        const ids = Object.keys(itm)
        //console.log(itm)
        // every 1 sec
        for(let idx of ids) {
          const stt = itm[idx]
          //console.log(stt)
          if(stt.gesture != null && stt.gesture >= 0) {
            // idx: user id
            if(!(idx in user_gesture)) {
              user_gesture[idx] = null
            }
            user_gesture[idx] = stt.gesture
          }
        }
      }

      // aggrigate
      console.log(user_gesture)
      let gstate = {}
      for(let uid in user_gesture) {
        let gid = user_gesture[uid]
        if(!(gid in gstate)) {
          gstate[gid] = 0
        }
        gstate[gid] += 1
      }
      //console.log(gstate)
      //console.log(this.currGestureState)
      this.currGestureState = gstate
    }
  },
}
</script>

<style scoped>

</style>