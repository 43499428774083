<template>
  <v-container>
    <v-row class="frame">
      <v-expansion-panels v-model="open_panels">
        <v-expansion-panel>
          <v-expansion-panel-header color="blue lighten-5">
            <span style="font-weight: bold">カメラ映像</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="panel-content" eager id="p-cnt">
            <v-row v-show="startedWebCam" justify="center" align-content="center" outlined id="row-video">
              <v-col cols="10" sm="6" md="8" lg="8" xl="6" class="container ml-n5">
                <div class="canvas-container">
                  <video id="vidCamera" class="video" :width="width" :height="height" playsinline>
                  </video>
                  <canvas id="cvsCamera" class="canvas" :width="width" :height="height">
                  </canvas>
                </div>
              </v-col>
            </v-row>
            <v-row v-show="!startedWebCam" justify="center" align-content="center">
              <v-col cols="12" class="mt-n4">
                <v-card :width="width" :height="height" justify="center" style="background-color: black">
                  <v-card-text class="text-center ms-auto" align-content="center">
                    <span style="font-weight: bold; color: white;">カメラ起動をクリックしてください</span>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
    <v-row v-if="debug_status">
      <v-col>STATUS: {{ status_str }}</v-col>
    </v-row>
  </v-container>
</template>

<script>
  import * as tf from '@tensorflow/tfjs'

  import * as ks4c from '@/Libs/ks4c.js'

  export default {
  name: 'CameraPanel',

  data: () => ({
    //
    video: null, // webcam
    canvas: null,
    canvasCtx: null,
    media: null,
    camera_margin: {w:0, h:0},
    faceDetectionIntervalTimer: null,
    emotionIntervalTimer: null,
    gestureIntervalTimer: null,
    blzfcModel: null,
    gestureModel: null,
    fcemoModel: null,
    currentSmileValue: 0,
    currentReactionValue: 0,
    currentNod: null,
    currentAttention: null,
    currentGestureClass: null,
    open: true,
    open_panels: 0,
    startedWebCam: false,
    video_area: {w: 0, h: 0},
//    smileReactionFrameCount: 0,
    image_paths: [],
    reactions: ['smile', 'attention', 'reaction', 'nod', 'like'],
    prev_emo: {x: null, y: null, w: null, h: null, clz: null, time: null},
    equeu: [],
    equeu_limit: 5,
    prev_gst: {x: null, y: null, w: null, h: null, clz: null, time: null},
    gqueu: [],
    onEmotionMark: -1,
    tmp_state: '', // FIXME: For DEBUG
    fqueu: [],
    fqueu_limit: 5,
    debug_status: false,
    squeue: null,
    rqueue: null,
    ssqueue: null,
    rsqueue: null,
  }),
  //
  computed: {
    height () {
      return this.video_area.h
    },
    width () {
      return this.video_area.w
    },
    status_str() { // FIXME: FOR debug
      return this.tmp_state
    }
  },
  //
  mounted () {
    //
    this.faceDetectionIntervalTimer = new ks4c.IntervalTimer(ks4c.RecongInfo.FaceDetectionIntervalMillis)
    this.emotionIntervalTimer = new ks4c.IntervalTimer(ks4c.RecongInfo.EmotionIntervalMillis)
    this.gestureIntervalTimer = new ks4c.IntervalTimer(ks4c.RecongInfo.GestureIntervalMillis)
    //
    this.unwatch = this.$store.watch(
      (state, getters) => getters['Application/getStartedWebcam'], (newv) => {
        if(newv) {
          const loadedModel = this.$store.getters['Application/getLoadedModel']
          if(loadedModel) {
            console.log('Loaded models')
            this.blzfcModel = this.$store.getters['Application/getBlzfcModel']
            this.gestureModel = this.$store.getters['Application/getGestureModel']
            this.fcemoModel = this.$store.getters['Application/getFcemoModel']
            this.startWebcam()
          }
        } else {
//          console.warn('LOGOUT')
          this.stopWebcam()
        }
      }

      //
    )
    this.initialize_queue()
    // FIXME:
    this.getCameraAreaSize()
    //
    window.addEventListener('resize', this.catchResized)

    // initialize image path
    for(let idx = 0; idx < this.reactions.length - 1; idx++){
      this.image_paths[idx] = '/images/reactions/' + ks4c.ReactionIMG[this.reactions[idx]]
    }
  },
  //
  beforeDestroy () {
    window.removeEventListener('resize', this.catchResized)
  },
  methods: {
    async startWebcam () {
      if (this.video != null) {
          alert("カメラはすでに使用されています。\nCamera is already started.");
          return;
      }

      this.startedWebCam = true
      this.video = document.getElementById("vidCamera");

      try {
        const _self = this
//        console.log('WebCam Resolution:')
//        console.log(ks4c.WebcamResolution)
        await navigator.mediaDevices.getUserMedia({
          audio: false,
          video: {
            width: { ideal: _self.width },
            height: { ideal: _self.height }
          }
        }).then(function (stream) {
          _self.media = stream
          _self.video.width = _self.video_area.w
          _self.video.height = _self.video_area.h
          _self.video.srcObject = stream
          _self.video.onloadedmetadata = () => {
            requestAnimationFrame(_self.canvasUpdate)
            _self.canvasUpdate()
          }
          _self.video.play()

          _self.canvas = document.getElementById("cvsCamera")
          _self.canvas.width = _self.video_area.w
          _self.canvas.height = _self.video_area.h
          _self.canvasCtx = _self.canvas.getContext('2d')
        })
        let t = this.media.getTracks()[0].getSettings()
        console.log(t)
      } catch (error) {
        console.log(error);
        alert(error);
      }
    },
    stopWebcam () {
      if (this.video == null) {
        alert("カメラはすでに停止しています。\nCamera is already stopped.");
        return;
      }
      this.video.srcObject = null
      this.video = null
      this.media = null
      this.equeu = []
      this.gqueu = []
      this.onEmotionMark = false
      this.canvasCtx.clearRect(0, 0, this.canvas.width, this.canvas.height)
      cancelAnimationFrame(this.canvasUpdate)
      this.canvasCtx = null
      this.canvas = null
      this.camera_margin = {w: 0, h: 0}
      this.set_video_area()
      this.fqueu = []

      this.startedWebCam = false

      this.initialize_queue()
    },
    initialize_queue(){
      this.squeue = null
      this.rqueue = null
      this.ssqueue = null
      this.rsqueue = null

      this.squeue = new ks4c.SizedQueue(3)
      this.rqueue = new ks4c.SizedQueue(3)
      this.ssqueue = new ks4c.SizedQueue(3)
      this.rsqueue = new ks4c.SizedQueue(3)
    },
    canvasUpdate () {
      if (this.video != null) {
        if (this.faceDetectionIntervalTimer.elapsed()) {
          this.callBlazeface()
        }
        requestAnimationFrame(this.canvasUpdate)
      } else {
          if(this.canvasCtx != null) {
            this.canvasCtx.clearRect(0, 0, this.canvas.width, this.canvas.height)
          }
          cancelAnimationFrame(this.canvasUpdate)
      }
    },
    async callBlazeface () {
      if (this.video == null) {
        console.warn('Video elem is invalid')
        return
      }

      // call blazeface
      const image = tf.browser.fromPixels(this.video);
      //console.log("image tensor:" + image.shape + "," + image.mean());

      try {
        // detect face
        const returnTensors = false
        const flipHorizontal = false
        const annotateBoxes = true
        const facePreds = await this.blzfcModel.estimateFaces(image, returnTensors, flipHorizontal, annotateBoxes)
        //console.log(facePreds)
        // select largest face
        let faceIndex = -1
        let maxFaceSize = 0
        for (let i = 0; i < facePreds.length; i++) {
          const faceSize = Math.max(facePreds[i].bottomRight[0] - facePreds[i].topLeft[0], facePreds[i].bottomRight[1] - facePreds[i].topLeft[1]);
          if (faceSize > maxFaceSize) { faceIndex = i; maxFaceSize = faceSize; }
        }
        const face = faceIndex < 0 ? null : facePreds[faceIndex]
        if(!face) {
          console.info('Not found a face')
        } else {
          console.info('Find a face')
          const or = this.estimateOrientation(face)
          const f = {face: face, orientation: or}
          this.$store.dispatch('FaceAnalyzer/setFaceState', f)
          this.$store.dispatch('FaceAnalyzer/setNodHistory', f)
        }

        // set prevState
        const cstate = this.$store.getters['Meeting/getUserState']
        this.$store.dispatch('Meeting/setPrevState', cstate)

        const ctx = this.canvasCtx
        // detect gesture
        if (this.gestureIntervalTimer.elapsed() && this.video != null) {
          // call gesture model
          const confidences = tf.tidy(() => {
            let inpImg = tf.image.resizeBilinear(image, [ks4c.GestureInputResolution.h, ks4c.GestureInputResolution.w], false, true);
            inpImg = tf.reverse(inpImg, -1); // RGB->BGR
            inpImg = inpImg.div(127.5).sub(1).expandDims(0);
            const prediction = this.gestureModel.predict(inpImg);
            inpImg = null // FIXME: for memory leak
            return prediction.dataSync();
          });
          let clz = -1
          let maxCnf = ks4c.RecongInfo.GenstureThres;
          for (let i = 0; i < confidences.length; i++) {
            if (confidences[i] >= maxCnf) { clz = i; maxCnf = confidences[i]; }
          }
//          console.log(`V: g-clz ${clz}`)
          this.currentGestureClass = clz
          this.summarizeState(null, null, this.currentGestureClass)
          this.push_gesture(clz)
          // clear the last image of gesture
          if(!this.changedGesture()) {
//            console.log(this.prev_gst)
            ctx.clearRect(this.prev_gst.x, this.prev_gst.y,
                          this.prev_gst.w, this.prev_gst.h)
          } else {
            this.draw_gesture_mark()
          }
        }

        if(face != null) {
          this.push_face(1)
        } else {
          this.push_face(0)
        }
        if(this.miss_face()) {
          this.reset_emotion()
        }
        // detect emotion
        let x1, y1, x2, y2;
        if (face != null) {
          // adjust face as square
          x1 = face.topLeft[0];
          y1 = face.topLeft[1];
          x2 = face.bottomRight[0];
          y2 = face.bottomRight[1];

          const cy = (y1 + y2) / 2;
          const w = x2 - x1;
          y1 = cy - w / 2;
          y2 = cy + w / 2;
        }

        if (face != null && this.emotionIntervalTimer.elapsed() && this.video != null) {
          const rx1 = Math.min(1, Math.max(0, x1 / image.shape[1]))   // relative
          const ry1 = Math.min(1, Math.max(0, y1 / image.shape[0]))
          const rx2 = Math.min(1, Math.max(0, x2 / image.shape[1]))
          const ry2 = Math.min(1, Math.max(0, y2 / image.shape[0]))

          // call emotional model
          if (rx1 < rx2 && ry1 < ry2) {
            const sr = tf.tidy(() => {
              const faceImg = tf.image.cropAndResize(image.expandDims(0), [[ry1, rx1, ry2, rx2]], [0], [64, 64])
              const prediction = this.fcemoModel.predict(faceImg)
              const smile = prediction[0].dataSync()[0]
              const react = prediction[1].dataSync()[0]
              return [smile, react]
            })
            this.squeue.enqueue(sr[0])
            this.rqueue.enqueue(sr[1])
            //this.currentSmileValue = sr[0]
            //this.currentReactionValue = sr[1]
            this.currentSmileValue = this.squeue.average()
            this.currentReactionValue = this.rqueue.average()
            const sf = sr[0] >= ks4c.RecongInfo.SmileThres ? 1 : 0
            const rf = sr[1] >= ks4c.RecongInfo.ReactionThres ? 1 : 0
            this.ssqueue.enqueue(sf)
            this.rsqueue.enqueue(rf)


            this.summarizeState(this.currentSmileValue, this.currentReactionValue, null);
          } else {
            this.summarizeState(this.currentSmileValue, this.currentReactionValue, null);
          }
          //
          if(this.changedAllEmotion()) {
            this.push_emotion(1)
          } else {
            this.push_emotion(0)
          }

          if (face != null) { // TODO: remove this condition
            this.tmp_state = `ONOFF: ${this.onoff_emotion()}\n EM: ${this.onEmotionMark}\nCE: ${this.changedEmotion()}\n`
//            console.log(`V: CE ${this.changedEmotion()}`)
//            console.log(`V: UD ${this.updown_emotion()}`)
//            console.log(`V: NF ${this.onoff_emotion()}`)
//            console.log(`V: OE ${this.onEmotionMark}`)
            if( (this.onoff_emotion() == 0)) {
//              console.log('V: clearRect')
              ctx.clearRect(this.prev_emo.x, this.prev_emo.y,
                            this.prev_emo.w, this.prev_emo.h)
              this.onEmotionMark = -1
            } else if( (this.onoff_emotion() > 0.0) && (this.changedEmotion() || this.onEmotionMark == -1)) {
              this.draw_emotion_mark()
//              this.onEmotionMark = true
            }
          }
        }
      } finally { tf.dispose([image]); }
    },
    // summarize for next sending: value: 0~1, gesture: 0(none)~
    summarizeState (smileValue, reactionValue, gesture) {
      let state = Object.assign({}, this.$store.getters['Meeting/getUserState'])
      //console.log(`VV: ST- ${JSON.stringify(state)}`)
      if (smileValue != null) {
        state.smile = smileValue >= ks4c.RecongInfo.SmileThres || (state.smile == null ? false : state.smile);
        state.smileValue = Math.max(state.smileValue, smileValue)
      }
      if (reactionValue != null) {
        state.reaction = reactionValue >= ks4c.RecongInfo.ReactionThres || (state.reaction == null ? false : state.reaction);
        state.reactionValue = Math.max(state.reactionValue, reactionValue)
      }
      if (gesture != null && gesture >= 0) { state.gesture = gesture; }

      // detection node
      state.nod = this.detectNod()

      // detecton attention
      state.attention = this.detectAttention()
      //
      //console.log(state)
      this.$store.dispatch('Meeting/setUserState', state)
    },
    //
    getCameraAreaSize() {
/*      let el = document.getElementById("p-cnt")
      console.log(`CR: ${el.offsetWidth}:${el.offsetHeight}`)
      this.video_area.w = el.offsetWidth - 12 * 1 // FIXME:
      this.video_area.h = el.offsetHeight - 12 * 2 // FIXME:
      */

      this.set_video_area()
      if(this.media != null) {
        const camera_info = this.media.getTracks()[0].getSettings()
        console.log(`CR: ${JSON.stringify(camera_info)}`)
        this.camera_margin.w = (this.video_area.w - camera_info.width) * 0.5
        this.camera_margin.h = (this.video_area.h - camera_info.height) * 0.5
        this.camera_margin.w = Math.round(this.camera_margin.w)
        this.camera_margin.h = Math.round(this.camera_margin.h)
        if(this.camera_margin.w < 0) {
          this.camera_margin.w = 0
        }
        console.log(`CR: ${JSON.stringify(this.camera_margin)}`)
      }
      // FIXME: when login, an error occurre.
      // but can't resize if the error remove
      if(this.canvas != null){
        this.canvas.width = this.video_area.w
        this.canvas.height = this.video_area.h
      }
    },
    catchResized() {
      console.log('resized window')
      console.log(window.innerWidth)
//      let t = this.media.getTracks()[0].getSettings()
//      console.log(t)
      this.getCameraAreaSize()
    },
    estimateOrientation(face) {
      const orientation = {roll: null, pitch: null, yaw: null}

      const info = face

      const rEye = info.landmarks[0] // right eye
      const lEye = info.landmarks[1] // left eye
      const nose = info.landmarks[2] // nose
      const mouth = info.landmarks[3] // mouth
      const rEar = info.landmarks[4] // right ear
      const lEar = info.landmarks[5] // left ear

      const eyesY = (rEye[1] + lEye[1]) / 2.0

      const roll = Math.atan2(lEye[1] - rEye[1], lEye[0] - rEye[0])

      let pitch = null
      if (mouth[1] < nose[1]) {
        pitch = 1.0
      } else if(nose[1] < eyesY) {
        pitch = -1.0
      } else {
        pitch = Math.log10((nose[1] - eyesY) / (mouth[1] - nose[1] + 1e-5))
        pitch = Math.min(1.0, Math.max(-1.0, pitch))
      }

      let yaw = null
      if (nose[0] < rEar[0]) {
        yaw = 1.0
      } else if(lEar[0] < nose[0]) {
        yaw = -1.0
      } else {
        yaw = Math.log10((lEar[0] - nose[0]) / (nose[0] - rEar[0] + 1e-5))
        yaw = Math.min(1.0, Math.max(-1.0, yaw))
      }

      orientation.roll = roll
      orientation.pitch = pitch
      orientation.yaw = yaw

      return orientation
    },
    detectNod(){
      const face = this.$store.getters['FaceAnalyzer/getFaceState']
      const hist = this.$store.getters['FaceAnalyzer/getNodHistory']
      if(face && hist.length >= 4) {
        let min = null
        let max = null
        let peakCnt = 0
        let incr = null

        for(let hf of hist) {
          if(!hf.orientation) {
            continue
          }

          let now_pitch = hf.orientation.pitch
          if(min != null && now_pitch > min && now_pitch - min >= ks4c.RecongInfo.NodPitchThres) {
            if(incr != true) {
              peakCnt ++
            }
            incr = true
            min = null
          }
          if (max != null && now_pitch < max && max - now_pitch >= ks4c.RecongInfo.NodPitchThres) {
            if(incr != false) {
              peakCnt ++
            }
            incr = false
            max = null
          }
          if(min == null || min > now_pitch) {
            min = now_pitch
          }
          if(max == null || max < now_pitch) {
            max = now_pitch
          }
          //console.log(`${incr}:${max}: ${min}:${peakCnt}`)
        }
        //console.log(peakCnt)
        return (peakCnt > this.nodCountThres())
      } else {
        console.log("can't detect nod!")
      }
      return false
    },
    nodCountThres(appSpeed=null){
      if(!appSpeed) {
        return 3
      }
      return 3
    },
    detectAttention () {
      const face_info = this.$store.getters['FaceAnalyzer/getFaceState']
      if(face_info == null) {
        return false
      }
      const or = face_info.orientation
      //console.log(or)
      let yaw = Math.max(0.0, 1 - Math.abs(or.yaw) / ks4c.RecongInfo.AttentionYawPitchRange)

      // TODO: move codes below this line if need
      //console.log(yaw)
      return (yaw > ks4c.RecongInfo.BooleanBaseThres)
    },
    durationSmileReaction(appSpeed=2) {
      let f = 2

      switch (appSpeed) {
        case 0:
          f = 2
          break
        case 1:
          f = 1
          break
        case 2:
          f = 1
          break
      }

      return f
    },
    durationSmileReactionAverage(appSpeed=2) {
      let f = 2

      switch (appSpeed) {
        case 0:
          f = 2
          break
        case 1:
          f = 1
          break
        case 2:
          f = 1
          break
      }

      return f
    },
    changedAllEmotion () {
      const state = this.$store.getters['Meeting/getUserState']

      const sflg = (this.currentSmileValue >= ks4c.RecongInfo.SmileThres)
      const rflg = (this.currentReactionValue >= ks4c.RecongInfo.ReactionThres)

      return (sflg || state.attention || state.nod || rflg)
    },
    changedEmotion() {
      let flg = false
      let cflg = Array(4).fill(false) // 0: smile, 1: reaction, 2: nod, 3: attention
      let pflg = Array(4).fill(false) // 0: smile, 1: reaction, 2: nod, 3: attention

      const state = this.$store.getters['Meeting/getUserState']
//      const pstate = this.$store.getters['Meeting/getPrevState']
//      console.log('V: changeEmotion')
//      cflg[0] = (this.currentSmileValue >= ks4c.RecongInfo.SmileThres)
//      cflg[1] = (this.currentReactionValue >= ks4c.RecongInfo.ReactionThres)
      cflg[0] = (this.ssqueue.average(2) > 0.0)
      cflg[1] = (this.rsqueue.average(2) > 0.0)
      cflg[2] = state.nod
      cflg[3] = state.attention

      pflg[0] = (this.onEmotionMark == 0)
      pflg[1] = (this.onEmotionMark == 1)
      pflg[2] = (this.onEmotionMark == 2)
      pflg[3] = (this.onEmotionMark == 3)

//      console.log(`V: cs ${JSON.stringify(cflg)}`)
//      console.log(`V: ps ${JSON.stringify(pflg)}`)

      for(let idx = 0; idx < cflg.length; idx++) {
        flg = flg || (pflg[idx] != cflg[idx])
      }

      return flg
    },
    changedGesture() {
      let flg = false
      flg = (this.currentGestureClass >= 0)
      return flg
    },
    push_emotion(cls) {
      if(this.equeu.length > this.equeu_limit) {
        this.equeu.shift()
      }
      this.equeu.push(cls)
    },
    onoff_emotion() {
//      console.log(`V: ${JSON.stringify(this.equeu)}`)
      let sum = 0
      for(let v of this.equeu) {
        sum += v
      }
      if(this.equeu.length > 0) {
        sum = sum / this.equeu.length
      }
//      console.log(`V: ${sum}`)
      return sum
    },
    push_gesture(cls) {
      if(this.gqueu.length > 1) {
        this.gqueu.shift()
      }
      this.gqueu.push(cls)
    },
    // draw top_left on canvas
    draw_emotion_mark() {
//      console.log('V: draw_emotion_mark')
      const ctx = this.canvasCtx
      const w = 100
      const h = 100
      const x = 0 + this.camera_margin.w
      const y = 0 + this.camera_margin.h
      // clear the last image of emotion
//      console.log(this.prev_emo)
      ctx.clearRect(this.prev_emo.x, this.prev_emo.y,
                    this.prev_emo.w, this.prev_emo.h)

      let img = new Image()
      img.onload = function () {
        ctx.drawImage(img, x, y, w, h)
      }
      const state = this.$store.getters['Meeting/getUserState']
//      console.log(`V: ${this.currentSmileValue}`)
//      console.log(`V: ${this.currentReactionValue}`)
      let sflg = (this.currentSmileValue >= ks4c.RecongInfo.SmileThres)
      let rflg = (this.currentReactionValue >= ks4c.RecongInfo.ReactionThres)
      let nflg = state.nod
      let aflg = state.attention
//      console.log(`V: ${sflg}:${rflg}:${nflg}:${aflg}`)
//      console.log('V: --------')
      if (this.currentSmileValue >= ks4c.RecongInfo.SmileThres) {
        ctx.fillStyle = "goldenrod"
        img.src = '/images/reactions/' + ks4c.ReactionIMG[this.reactions[0]] // smile
        this.onEmotionMark = 0
      } else if (this.currentReactionValue >= ks4c.RecongInfo.ReactionThres) {
        ctx.fillStyle = "green"
        img.src = '/images/reactions/' + ks4c.ReactionIMG[this.reactions[2]] // reaction
        this.onEmotionMark = 1
      } else if(state.nod) {
        ctx.fillStyle = ks4c.EmotionColors.nod
        img.src = '/images/reactions/' + ks4c.ReactionIMG[this.reactions[3]] // nod
        this.onEmotionMark = 2
      } else if(state.attention) {
        img.src = '/images/reactions/' + ks4c.ReactionIMG[this.reactions[1]] // attention
        ctx.fillStyle = ks4c.EmotionColors.attention
        this.onEmotionMark = 3
      }
      //
      this.prev_emo.x = x
      this.prev_emo.y = y
      this.prev_emo.w = w
      this.prev_emo.h = h

    },
    draw_gesture_mark() {
      const ctx = this.canvasCtx
      const w = 100
      const h = 100
      const fontSize = parseInt(h * .75, 10)
      const x = this.canvas.width - w - this.camera_margin.w
      const y = 0 + this.camera_margin.h
      ctx.fillStyle = "rgba(255,255,255,0.5)"
      ctx.fillRect(x, y, w, h);
      ctx.fillStyle = "orange";
      ctx.font = fontSize + "px sans-serif"
      const fr_x = Math.floor(x + (w - fontSize) * .5)
      const fr_y = Math.floor(y + (h + fontSize) * .5 - 10)
      ctx.fillText(ks4c.Emojis.GestureEmojis[this.currentGestureClass],
                    fr_x, fr_y)
      //
      this.prev_gst.x = x
      this.prev_gst.y = y
      this.prev_gst.w = w
      this.prev_gst.h = h
    },
    push_face(val) {
      if(this.fqueu.length > this.fqueu_limit) {
        this.fqueu.shift()
      }
      this.fqueu.push(val)
    },
    reset_emotion() {
      this.onEmotionMark = -1
      this.equeu = []
      this.canvasCtx.clearRect(this.prev_emo.x, this.prev_emo.y,
              this.prev_emo.w, this.prev_emo.h)

      let sst = { smile: false, smileValue: 0, reaction: false, reactionValue: 0, gesture: null, nod: false, attention: false }
      this.$store.dispatch('Meeting/setUserState', sst)
    },
    miss_face() {
      let flg = false

      const sum = this.fqueu.reduce((p, c) => { return p + c})

      if(sum == 0) {
        flg = true
      }

      return flg
    },
    set_video_area() {
      let el = document.getElementById("p-cnt")
      console.log(`CR: ${el.offsetWidth}:${el.offsetHeight}`)
      this.video_area.w = el.offsetWidth - 12 * 1 // FIXME:
      this.video_area.h = el.offsetHeight - 12 * 2 // FIXME:
    }
  },
}
</script>

<style scoped>
  .container {
  }
  .canvas-container{
    width: 100%;
    height: 100%;
    height: 100hv;
    position: relative;
    padding: 0em 0em;
    margin:-1;
  }
  .video {
    top: 0;
    left: 0;
  }
  .canvas {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
  }
</style>