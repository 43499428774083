"use strict"

import Vue from "vue"
import Vuex from "vuex"
import VueLocalStorage from "vue-localstorage"

import modules from './modules'

Vue.use(Vuex)
Vue.use(VueLocalStorage, { name: 'ls', bind: true })

// generate store
const store = new Vuex.Store({
    modules,
    strict: process.env.NODE_ENV !== 'production'
})

export default store