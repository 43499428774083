const state = {
  loadedModel: false,
  blzfcModel: null,
  fcemoModel: null,
  gestureModel: null,
  startedWebcam: false,
  machineInfo: null
}

const getters = {
  getLoadedModel (state) {
    return state.loadedModel
  },
  getBlzfcModel (state) {
    return state.blzfcModel
  },
  getFcemoModel (state) {
    return state.fcemoModel
  },
  getGestureModel (state) {
    return state.gestureModel
  },
  getStartedWebcam (state) {
    return state.startedWebcam
  },
  getMachineInfo (state) {
    return state.machineInfo
  }
}

const mutations = {
  setLoadedModel (state, pyld) {
    state.loadedModel = pyld
  },
  setBlzfcModel (state, pyld) {
    state.blzfcModel = pyld
  },
  setFcemoModel (state, pyld) {
    state.fcemoModel = pyld
  },
  setGestureModel (state, pyld) {
    state.gestureModel = pyld
  },
  setStartedWebcam (state, pyld) {
    state.startedWebcam = pyld
  },
  setMachineInfo (state, pyld) {
    state.machineInfo = pyld
  }
}

const actions = {
  setLoadedModel({ commit }, pyld) {
    commit('setLoadedModel', pyld)
  },
  setBlzfcModel({ commit }, pyld) {
    commit('setBlzfcModel', pyld)
  },
  setFcemoModel({ commit }, pyld) {
    commit('setFcemoModel', pyld)
  },
  setGestureModel({ commit }, pyld) {
    commit('setGestureModel', pyld)
  },
  setStartedWebcam({ commit }, pyld) {
    commit('setStartedWebcam', pyld)
  },
  setMachineInfo( { commit }, pyld) {
    commit('setMachineInfo', pyld)
  }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}