// Webcam part ///////////////////////////////////////////////
const WebcamResolution = { w: 320, h: 240 }
const GestureInputResolution = { w: 160, h: 120 }

//Colors
const EmotionColors = {
  'smile': "#daa520", // GoldenRod
  'attention': "#808080", // Gray
  'reaction': "#008000", // Green
  'nod': "#ff69b4", // HotPink
  'anger': "#e95378", // anger
  'contempt': "#9f9f9f", // contempt
  'disgust': "#000000", //
  'fear': "#4d4497", //
  'joy': "#f7b400", //
  'sadness': "#00adcb", //
  'surprise': "#70b92a", //
  'engagement': "#00cbeb", //
  'valence': "#f0c7e0", //
  'none': "#ffffff",
  'like': "#ffa500"
}

// simple avatar
const SimpleAvatar = ['face.png', 'up.png', 'down.png', 'right.png', 'left.png', 'rolling_right.png', 'rolling_left.png']
const EmotionAvatar = ['anger.png', 'contempt.png', 'disgust.png', 'fear.png', 'joy.png', 'sadness.png', 'surprise.png']
// Emoji
class Emojis {
// { Ng, Hand, Ok, ThumbUp, Apology, Salute, One, Two, Three, Four, Pray, GutsOne, GutsBoth, Handclap }
  static GestureEmojis = ["🙅", "🖐", "👌", "👍", "🙇", "👮", "①", "②", "③", "④", "😇", "🙋‍", "🤷‍", "👏"];
  static SmileEmoji = "😀";
  static ReactionEmoji = "😮";
  static AttentionEmoji = "😐";
  static NodEmoji = "😌";
}

// Reaction IMG
class ReactionIMG {
  static attention = "Attention.png"
  static nod = "Nod.png"
  static reaction = "Reaction.png"
  static smile = "Smile.png"
}

class RecongInfo {
  static GenstureThres = 0.5
  static SmileThres = 0.2
  static ReactionThres = 0.5
  static NodPitchThres = 0.125
  static AttentionYawPitchRange = 0.5
  static AttentonRollRange = 15.0 / 360.0 * (Math.PI * 2.0)
  static BooleanBaseThres = 0.5
  // Emotion and gesture are called after face detection, so these values must be greater than face
  static FaceDetectionIntervalMillis = 100; // best effort
  static EmotionIntervalMillis = 500; // best effort
  static GestureIntervalMillis = 250; // best effort
}

//
class MeetingInfo {
  static DataVersion = 1
  static AwsRegion = "ap-northeast-1"   // does not use in connect API
  static ConnApiId = "t1gnil56ii"
//  static ConnApiId = "jc6zvv4uie"
  static ConnStageName = "prod"
  static MachineInfo = generatePseudoUuid()       // TODO must be held in Cookie
  static DummyRoom = "1"
  static MinRetrivalCount = 6 // more than max interval
  static EmotionJoy = 4
  static EmotionSurprise = 6
  static CurrentStateStringLength = 10
  static NoUpdateMemberHoldingMillis = 10 * 1000
  static BgAnimationDuration = 250 * 1000 // 250 second
  static MaxCommentSeconds = 3
  static MaxCommentLength = 10
}

// TF backend
//const TfBackend = 'webgl';

//
class IntervalTimer {
  constructor(interval) {
    this.interval = interval;
    this.lastTime = new Date().getTime();
    console.log(this.interval)
  }
  elapsed() {
    const nowTime = new Date().getTime();
    if (nowTime - this.lastTime >= this.interval) {
      this.lastTime = nowTime;
      return true;
    }
    return false;
  }
}

// Queue
class SizedQueue {
  constructor(size) {
    this._size = size
    this._queue = Array(this._size).fill(null)
  }

  enqueue(val) {
    if(this._queue.length > this._size) {
      this.dequeue()
    }
    this._queue.push(val)
  }

  dequeue() {
    this._queue.shift()
  }

  average(avg_size=2){
    let sum = 0
    let clen = 1
    if(this._queue.length < avg_size){
      clen = this._queue.length
    } else {
      clen = avg_size
    }

    for(let idx = 0; idx < clen; idx++) {
      sum += this._queue[idx]
    }

    let avg = sum / clen

    return avg
  }
}
// meeting part
function generatePseudoUuid () {
  // https://qiita.com/psn/items/d7ac5bdb5b5633bae165
  // https://github.com/GoogleChrome/chrome-platform-analytics/blob/master/src/internal/identifier.js
  // const FORMAT: string = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx";
  let chars = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".split("");
  for (let i = 0, len = chars.length; i < len; i++) {
    switch (chars[i]) {
      case "x":
        chars[i] = Math.floor(Math.random() * 16).toString(16);
        break;
      case "y":
        chars[i] = (Math.floor(Math.random() * 4) + 8).toString(16);
        break;
    }
  }
  return chars.join("");
}

function getParamsFromJson (json, target, params){
  for (let i = 0; i < params.length; i++) {
    const param = params[i];
    let val = null
    if(param == "UserId"){
      val = parseInt(json[param], 10)
    } else {
      val = json[param]
    }
    if (!val) { return false; }
    target[param] = val;
  }
  return true;
}

function toHex4 (v) {
  return v == null ? 'ZZZZ' : ('0000' + v.toString(16)).slice(-4);
}

function toHex2 (v) {
  return v == null ? 'ZZ' : ('00' + v.toString(16)).slice(-2);
}

function toHex1 (v) {
  return v == null ? 'Z' : (v % 16).toString(16);
}

function toHexSigned2 (v) {
  return v == null ? 'ZZ' : toHex2(v + 128);
}

function fromHex4 (s) { return s == 'ZZZZ' ? null : parseInt(s, 16); }
function fromHex2 (s) { return s == 'ZZ' ? null : parseInt(s, 16); }
function fromHex1 (s) { return s == 'Z' ? null : parseInt(s, 16); }
function fromHexSigned2 (s) { return s == 'ZZ' ? null : fromHex2(s) - 128; }

//

export {
  WebcamResolution,
  GestureInputResolution,
  EmotionColors,
  SimpleAvatar,
  EmotionAvatar,
  RecongInfo,
  Emojis,
  ReactionIMG,
  IntervalTimer,
  MeetingInfo,
  SizedQueue,
  getParamsFromJson,
  generatePseudoUuid,
  toHex1,
  toHex2,
  toHex4,
  toHexSigned2,
  fromHex1,
  fromHex2,
  fromHex4,
  fromHexSigned2
}