<template>
  <v-container>
    <v-row v-for="(item, idx) in gesture_state" :key="idx">
      <v-col cols="6">
        <span style="font-size: x-large">{{ item['name'] }}</span>
      </v-col>
      <v-col cols="6">
        {{ markers[item['gesture']] }}
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import * as ks4c from '@/Libs/ks4c.js'

export default {
  name: 'g_member',
  data() {
    return {
      markers: ks4c.Emojis.GestureEmojis,
      unwatchNsState: null,
      unwatchStoredState: null,
      currGestureState: {},
    }
  },
  //
  mounted() {
    const _self = this
    // watch state
    this.unwatchNsState = this.$store.watch(
      (state, getters) => getters['Meeting/getLastNsState'], () => {
        _self.aggrigate_gesture()
      }
    )
    this.unwatchStoredState = this.$store.watch(
      (state, getters) => getters['Meeting/getStoredState'], () => {
        _self.aggrigate_gesture()
      }
    )
  },
  //
  beforeDestroy() {
    this.unwatchNsState = null
    this.unwatchStoredState = null
  },
  //
  computed: {
    gesture_state () {
      return this.currGestureState
    }
  },
  //
  methods: {
    aggrigate_gesture () {
      const measuring = this.$store.getters['Meeting/getMeasuringState']
      let state = null
      if(measuring == 1) {
        state = this.$store.getters['Meeting/getStoredState']
      } else if(measuring == 2) {
        return
      } else {
        state = this.$store.getters['Meeting/getLastNsState']
      }
//      console.log(state)
      let user_gesture = {} // userID:  gesture_id
      //
      for(let itm of state) {
        if(!itm) { continue }

//        console.log(itm)
        const ids = Object.keys(itm)
//        console.log(itm)
        // every 1 sec
        for(let idx of ids) {
          const stt = itm[idx]
//          console.log(stt)
          if(stt.gesture != null && stt.gesture >= 0) {
            if(!(idx in user_gesture)) {
              user_gesture[idx] = { 'name': null, 'gesture': null }
            }
            user_gesture[idx]['name'] = stt.name
            user_gesture[idx]['gesture'] = stt.gesture
          }
        }
      }
//      console.log(user_gesture)
      // clean up
/*      let out = {}
      const ids = Object.keys(gstate)
      for(let idx of ids) {
        if(gstate[idx]['gesture'] != null ) {
          out[idx] = gstate[idx]
        }
      }
      console.log(out) */
//      console.log(this.currGestureState)
      this.currGestureState = user_gesture
    }
  },
}
</script>

<style scoped>

</style>