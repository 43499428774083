const state = {
  meetingID: '',
  passPhrase: '',
  name: '',
  userID: null,
  userState: null, // { smile: false, smileValue: 0, reaction: false, reactionValue: 0, gesture: null, nod: false, attention: false }
  meetingState: null,
  joinStatus: 0, // 0: exit 1: joining, 2: joined
  lastNsState: new Array(10), // for normal gesture view
  storedState: [],
  measuringState: 0,  // 0: stop, 1: running, 2: pause
  comment: '',  //
  memberComments: {}, // {key: element, key': element', ....} key is uid, element is object: {uid: ..., sec: ..., message: ...}
  muteMembers: [], // including muting user id
  prevState: null, // previous user state
}

const getters = {
  getMeetingID (state) {
    return state.meetingID
  },
  getPassPhrase (state) {
    return state.passPhrase
  },
  getName(state) {
    return state.name
  },
  getUserID(state) {
    return state.userID
  },
  getUserState(state) {
    return state.userState
  },
  getPrevState(state) {
    return state.prevState
  },
  getMeetingState(state) {
    return state.meetingState
  },
  getMemberInMeeting(state) {
    let ret = 0
    if(state.meetingState) {
      ret = Object.keys(state.meetingState).length
    }

    return ret
  },
  getStateCount(state) {
    let ret = {smile: 0, attention: 0, reaction: 0, nod: 0, like: 0}

    if(state.meetingState) {
      let ky = Object.keys(state.meetingState)
      for(let idx = 0; idx < ky.length; idx++){
        if(state.meetingState[ky[idx]].smile) {
          ret.smile += 1
        }
        if(state.meetingState[ky[idx]].attention) {
          ret.attention += 1
        }
        if(state.meetingState[ky[idx]].reaction) {
          ret.reaction += 1
        }
        if(state.meetingState[ky[idx]].nod) {
          ret.nod += 1
        }
        if(state.meetingState[ky[idx]].gesture == 3) {
          ret.like += 1
        }
      }
    }

    return ret
  },
  // eslint-disable-next-line
  getAttentionCount(state) {
    let ret = 0
    return ret
  },
  getLastNsState(state) {
    return state.lastNsState
  },
  getStoredState(state) {
    return state.storeState
  },
  getMeasuringState(state) {
    return state.measuringState
  },
  getComment(state) {
    return state.comment
  },
  getMemberComments(state) {
    return state.memberComments
  },
  getMuteMembers(state) {
    return state.muteMembers
  },
  getJoinStatus(state) {
    return state.joinStatus
  }
}

const mutations = {
  setMeetingID(state, pyld) {
    state.meetingID = pyld
  },
  setPassPhrase(state, pyld) {
    state.passPhrase = pyld
  },
  setName(state, pyld) {
    state.name = pyld
  },
  setUserID(state, pyld) {
    state.userID = pyld
  },
  setUserState(state, pyld) {
    state.userState = pyld
  },
  setPrevState(state, pyld) {
    state.prevState = pyld
  },
  setMeetingState(state, pyld) {
    state.meetingState = pyld
  },
  // pyld: {index: idx, value: value of target}
  setLastNsState(state, pyld) {
    state.lastNsState[pyld['index']] = pyld['value']
  },
  // eslint-disable-next-line
  shiftLastNsState(state, pyld) {
    state.lastNsState.splice(0, 1)
  },
  pushLastNsState(state, pyld) {
    state.lastNsState.push(pyld)
  },
  // pyld: {index: idx, value: value of target}
  setStoredState(state, pyld) {
    state.StoredState[pyld['index']] = pyld['value']
  },
  // eslint-disable-next-line
  shiftStoredState(state, pyld) {
    state.StoredState.splice(0, 1)
  },
  pushStoredState(state, pyld) {
    state.StoredState.push(pyld)
  },
  //
  setMeasuringState(state, pyld) {
    state.storedState = pyld
  },
  setComment(state, pyld) {
    state.comment = pyld
  },
  setMemberComments(state, pyld) {
    state.memberComments = pyld
  },
  mergeMemberComments(state, pyld) {
    for(let ky in pyld) {
      if(ky in state.memberComments) {
        if(pyld[ky].msg != '') {
          state.memberComments[ky] = pyld[ky]
        } else {
          // TODO: neeed to implement if comment time is over
        }
      } else {
        state.memberComments[ky] = pyld[ky]
      }
    }
  },
  setMuteMembers(state, pyld) {
    state.muteMembers = pyld
  },
  setJoinStatus(state, pyld) {
    console.log(state)
    console.log(pyld)
    state.joinStatus = pyld
  }
}

const actions = {
  setMeetingID({ commit }, pyld) {
    commit('setMeetingID', pyld)
  },
  setPassPhrase({ commit }, pyld) {
    commit('setPassPhrase', pyld)
  },
  setName({ commit }, pyld) {
    commit('setName', pyld)
  },
  setUserID({ commit }, pyld) {
    commit('setUserID', pyld)
  },
  setUserState({ commit }, pyld) {
    commit('setUserState', pyld)
  },
  setPrevState({ commit }, pyld) {
    commit('setPrevState', pyld)
  },
  setMeetingState({ commit }, pyld) {
    commit('setMeetingState', pyld)
  },
  setLastNsState({ commit }, pyld) {
    commit('setLastNsState', pyld)
  },
  updateLastNsState({ commit }, pyld) {
    commit('shiftLastNsState')
    commit('pushLastNsState', pyld)
  },
  updateStoredState({ commit }, pyld) {
    commit('pushStoredState', pyld)
  },
  setStoredState({ commit }, pyld) {
    commit('setStoredState', pyld)
  },
  setMeasuringState({ commit }, pyld) {
    commit('setMeasuringState', pyld)
  },
  setComment({ commit }, pyld) {
    commit('setComment', pyld)
  },
  setMemberComments({ commit }, pyld) {
    commit('setMemberComments', pyld)
  },
  mergeMemberComments({ commit }, pyld) {
    commit('mergeMemberComments', pyld)
  },
  setMuteMembers({ commit }, pyld) {
    commit('setMuteMembers', pyld)
  },
  setJoinStatus({ commit }, pyld) {
    commit('setJoinStatus', pyld)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}