<template>
  <v-container class="container mg-0 pa-0">
    <v-menu
      v-model="show_menu"
      absolute
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-card :color="background_color" v-bind="attrs" v-on="on" elevation="0">
          <v-img :src="image_path" :style="background_color" class="align-end">
            <v-card-text class="ma-0 pa-0">
              <v-container class="ma-0 pa-0">
                <v-row dense>
                  <v-col class="comment ma-0 pa-0"> {{ comment }} </v-col>
                </v-row>
                <v-row dense>
                  <v-col class="marker"> {{ icon }} </v-col>
                </v-row>
                <v-row dense>
                  <v-col class="name ma-0 pa-0"> {{ name }} </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-img>
        </v-card>
      </template>
      <v-list>
        <v-list-item @click="mute_member">
          <v-list-item-title>{{ mute_label }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-container>
</template>

<script>
import * as ks4c from '@/Libs/ks4c.js'

export default {
  name: 'MemberCard',
  props: {
    id: String,
    name: String,
    info: Object
  },
  data: () => ({
    currState: null,
    lastBgChanged: null,
    animationCompleted: false,
    prevState: null,
    bg_code: ks4c.EmotionColors.none,
    icon_str: '',
    icon_state: {state: '', time: null},
    bg_time: null,
    unwatch: null,
    unwatch_comment: null,
    comment_data: '',
    show_menu: false,
    menu_items: []
  }),
  //
  computed: {
    image_path () {
      let face_dir = 0
      let img_base_path = '/images/simple/'
      let img = ''
//      console.log(this.currState)
      if(this.currState) {
        face_dir = this.currState.faceDirection
      } else {
        console.warn("invalid state")
        return img_base_path + ks4c.SimpleAvatar[0]
      }
      console.log(`FD: ${face_dir}`)
      if(face_dir > 0) {
        img = img_base_path + ks4c.SimpleAvatar[face_dir - 1]
      } else {
        img = img_base_path + ks4c.SimpleAvatar[0]
      }
      if(this.currState.smile){
        img = img_base_path + 'joy.png'
      } else if(this.currState.reaction) {
        img = img_base_path + 'surprise.png'
      }
      return img
    },
    background_color () {
      return this.bg_code
    },
    icon () {
      return this.icon_str
    },
    comment () {
      return this.comment_data
    },
    mute_label () {
      let lbl_str = '非表示にします(反映に数秒かかります)'

      return lbl_str
    },
    cmt_class () {
      if(this.comment_data != '') {
        return 'comment pt-1 mt-2'
      } else {
        return 'pa-0 ma-0'
      }
    },
    mkr_class () {
      if(this.icon_str != '') {
        return 'marker pa-2 ma-0'
      } else {
        return 'pa-0 ma-0'
      }
    }
  },
  //
  mounted () {
//    console.log("MemberCard: Mounted")
    const _self = this
    this.unwatch = this.$store.watch(
      (state, getters) => getters['Meeting/getMeetingState'], (newv) => {
        if(newv) {
          //
//          console.log(`VV: ${_self.id}`)
//          console.log(`VV: ${JSON.stringify(newv)}`)
          if(newv[_self.id]){
            _self.currState = newv[_self.id]
            if(_self.prevState && (_self.currState.emotion != _self.prevState.emotion)) {
              //
              _self.bg_code = _self.decideEmotionalBgColor()
            } else {
              let nowt = Date.now()
              // FIXME:
              if(2500 < nowt - _self.currState.timestamp) {
                _self.bg_code = ks4c.EmotionColors.none
              } else {
                _self.bg_code = _self.decideEmotionalBgColor()
              }
            }
          } else {
            console.warn(`Not Found obj: ${_self.id}`)
          }
        }
        _self.decideIcon()
        _self.prevState = _self.currState

        // FIXME: Comment
        _self.updateComment()
      }
    )
    this.unwatch_comment = this.$store.watch(
      (state, getters) => getters['Meeting/getMemberComments'], (newv) => {
        if(newv) {
          //
//          console.log(newv)
          if(newv[_self.id]){
            // TODO: Need to check comment time
            let cmtInfo = newv[_self.id]
            if(cmtInfo.message != null) {
              _self.comment_data = cmtInfo.message
            }
          }
        }
      }
    )
  },
  //
  beforeDestory() {
    this.unwatch = null
    this.unwatch_comment = null
  },
  //
  methods: {
    //
    updateComment() {
      let cmt = this.$store.getters['Meeting/getMemberComments']
      //
      if(cmt[this.id]){
        let cmtInfo = cmt[this.id]
        if(cmtInfo.message != '') {
          this.comment_data = cmtInfo.message
        }
      }
    },
    //
    changedEmotionState () {
      let ret = false

      ret = (this.currState.emotion != this.prevState.emotion)

      return ret
    },
    //
    changedGestureState () {
      let ret = false

      ret = (this.currState.gesture != this.prevState.gesture)

      return ret
    },
    //
    decideEmotionalBgColor() {
      //console.log(`VV: EBG ${JSON.stringify(this.currState)}`)
      const cs = this.currState
      let bg_color = "#ffffff"
      if(cs.nod) {
        bg_color = ks4c.EmotionColors.nod
      } else if(cs.smile){
        bg_color = ks4c.EmotionColors.smile
      } else if(cs.reaction) {
        bg_color = ks4c.EmotionColors.reaction
      } else if(cs.attention) {
        bg_color = ks4c.EmotionColors.attention
      }

      //console.log(`VV: EBG ${bg_color}`)
      return bg_color
    },
    //
    decideIcon() {
      const cs = this.currState
      if(cs.gesture) {
        this.icon_str = ks4c.Emojis.GestureEmojis[cs.gesture]
        this.icon_state.state = this.icon_str
        this.icon_state.time = Date.now()
      } /*else if(cs.smile) {
        this.icon_str = ks4c.Emojis.SmileEmoji
        this.icon_state.state = this.icon_str
        this.icon_state.time = Date.now()
      } else if (cs.attention) {
        this.icon_str = ks4c.Emojis.AttentionEmoji
        this.icon_state.state = this.icon_str
        this.icon_state.time = Date.now()
      } else if (cs.reaction) {
        this.icon_str = ks4c.Emojis.ReactionEmoji
        this.icon_state.state = this.icon_str
        this.icon_state.time = Date.now()
      } else if(cs.Nod) {
        this.icon_str = ks4c.Emojis.NodEmoji
        this.icon_state.state = this.icon_str
        this.icon_state.time = Date.now()
      } */ else {
        if(2500 < Date.now() - this.icon_state.time) {
          this.icon_str = ''
          this.icon_state.state = ''
          this.icon_state.time = null
        }
      }
    },
    mute_member() {
//      console.log('MUTE')
//      const uid = Number(this.id)
      const mm = this.$store.getters['Meeting/getMuteMembers']
      let mmm = this.$_copyObject(mm)
//      mmm.push(uid)
      mmm.push(this.id)
      this.$store.dispatch('Meeting/setMuteMembers', mmm)
    },
    //
    $_copyObject(obj) {
      let tmp = JSON.stringify(obj)
      return JSON.parse(tmp)
    }
  }

}
</script>

<style scoped>
  .container {
    border-style: none;
  }
  .comment {
    position: absolute;
    top:  0;
    left: 0;
    z-index: 10;
    text-align: center;
    font-weight: bold;
    font-size: 1.0em;
    background-color: rgba(255, 255, 255, 0.8);
    /* background-color: blue; */
    border-style: none;
    line-height:normal;
  }
  .marker {
    position: absolute;
    top:  0;
    left:  0;
    z-index: 20;
    padding: 40% 0% 0%;  
    text-align: center;
    font-weight: bold;
    font-size: 225%;
    /* background-color: yellow; */
    border-style: none;
  }
  .name {
    position: absolute;
    bottom:  0;
    left: 0;
    z-index: 0;
    text-align: center;
    vertical-align: middle;
    font-weight: bold;
    background-color: rgba(255, 255, 255, 0.8);
    border-style: none;
    font-size: 1.0em;
    line-height:normal;
  }
  .card-text {
    border-width: 0px;
  }

</style>