<template>
  <v-container>
    <v-row class="frame">
      <v-expansion-panels v-model="open_panels">
        <v-expansion-panel>
          <v-expansion-panel-header color="blue lighten-5">
            <span style="font-weight: bold">サマリー情報</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="panel-content" eager>
            <v-row class="ma-0 pa-0">
              <template v-for="(item, idx) in reactions">
                <v-col cols="3" class="ml-0 mr-0 pl-0 pr-0" :key="`lbl-${idx}`">
                  <v-row class="index_column text-center ma-0 pa-0">
                    <v-col cols="6">
                      <v-row dense class="flex-column mt-n2 ml-n3 mr-n3">
                        <v-col cols="12" class="action_name pa-0 ma-0">
                          <span style="font-weight: bold">{{ names[idx] }}</span>
                        </v-col>
                        <v-col cols="12" style="font-weight: bold" class="action_counter ml-0 pa-0">
                          <span>{{ counters[idx] }} / {{ num_of_member }}</span>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="6">
                      <v-row>
                        <v-col class="action_marker pa-0 ma-0">
                          <template v-if="idx == 4">
                            {{ markers[idx] }}
                          </template>
                          <template v-else>
                            <v-img contain max-height="40" :src="img_path[idx]"></v-img>
                          </template>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="9" class="mt-0 pa-0" :key="`chart-${idx}`">
                  <VueC3 :handler="handlers[idx]" :id="'lsg-' + idx"></VueC3>
                </v-col>
              </template>
            </v-row>
            <v-row class="note">
              過去30秒間にそれぞれの反応を示した参加者の数を表示します。
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </v-container>
</template>

<script>
  import * as ks4c from '@/Libs/ks4c.js'

  import Vue from 'vue'
  import VueC3 from 'vue-c3'
  import 'c3/c3.min.css'

  export default {
    name: 'live_summary',
    //
    components: {
      VueC3,
    },
    //
    data: () => ({
      reactions: ['smile', 'attention', 'reaction', 'nod', 'like'],
      names: ['笑顔', '注目', 'リアクション', 'うなずき', 'いいね'],
      markers: [ks4c.Emojis.SmileEmoji, ks4c.Emojis.AttentionEmoji,
        ks4c.Emojis.ReactionEmoji, ks4c.Emojis.NodEmoji, ks4c.Emojis.GestureEmojis[3]],
      counters: [0, 0, 0, 0, 0],
      img_path: [],
      //
      handlers: [],
      options: [],
      //
      open: true,
      //
      unwatch: null,
      open_panels: 0
    }),
    //
    computed: {
      num_of_member () {
        let ret = 0
        ret = this.$store.getters['Meeting/getMemberInMeeting']

        return ret
      }
    },
    //
    created() {
      console.log('Initialize Handlers')
      for(let idx = 0; idx < this.reactions.length; idx++) {
        let init_data = new Array(30).fill(0)
        this.handlers[idx] = new Vue()
        this.options[idx] = {
          data: {
            columns: [['counter', ...init_data]],
            type: 'bar',
            colors: {
              counter: ks4c.EmotionColors[this.reactions[idx]]
            }
          },
          axis:{
            x: { show: false },
            y: { show: false },
          },
          regions: [
            {axis: 'x', class: 'chart-bg-00'}
          ],
          legend: { show: false },
          bar: {
            width: { ratio: 1.0 }
          },
          size: { height: 70, },
          transition: { duration: 0 },
          interaction: { enabled: false },
          bindto: `#lsg-${idx}`
        }
      }
    },
    //
    mounted () {
      console.log('Mounted')
      for(let idx = 0; idx < this.reactions.length; idx++) {
        this.handlers[idx].$emit('init', this.options[idx])
      }
      console.log('register watch')
      const _self = this
      this.unwatch = this.$store.watch(
        (state, getters) => getters['Meeting/getMeetingState'], () => {
          let nc = _self.$store.getters['Meeting/getStateCount']
          _self.counters = []
          _self.counters[0] = nc.smile
          _self.counters[1] = nc.attention
          _self.counters[2] = nc.reaction
          _self.counters[3] = nc.nod
          _self.counters[4] = nc.like

          _self.updateAllCharts()
        }
      )

      // initialize image path
      for(let idx = 0; idx < this.reactions.length - 1; idx++){
        this.img_path[idx] = '/images/reactions/' + ks4c.ReactionIMG[this.reactions[idx]]
      }
//      console.log(this.img_path)
    },
    //
    beforeDestory() {
      this.unwatch = null
    },
    //
    methods: {
      updateAllCharts () {
        const num_mem = this.$store.getters['Meeting/getMemberInMeeting']
        const _self = this
        for(let idx = 0; idx < this.handlers.length; idx++) {
          this.handlers[idx].$emit('dispatch', (chart) => {
            let data = chart.data('counter')[0].values.map(v => v.value)
            data.shift()
            data.push(_self.counters[idx])
            chart.axis.max({y: num_mem})
            chart.load({columns: [['counter', ...data]] })
          })
        }
      },
    }

  }
</script>

<style scoped>
  .index_column {
    margin-left: auto;
    margin-right: auto;
  }
  .action_name {
    display: block;
    font-size: 50%;
    height: 20%;
    text-align: center;
  }
  .action_marker {
    display: block;
    font-size: 200%;
  }
  .action_counter {
    display: block;
    font-size: 50%;
  }
  .note {
    font-size: 30%
  }
  .graph_view {
    height: 60px;
  }
  .frame {
    border: solid gray;
    border-width: 1px;
  }
  .c3-region.chart-bg-00 {
    fill: #F5F5F5;
  }
</style>