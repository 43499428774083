<template>
  <v-container>
    <v-row class="frame">
      <v-expansion-panels v-model="open_panels">
        <v-expansion-panel>
          <v-expansion-panel-header color="blue lighten-5">
            <span style="font-weight: bold">仮想会議参加者の一覧</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="panel-content" eager>
            <v-list class="overflow-y-auto member-list">
              <v-row class="member-view" dense>
                <v-col xs="2" sm="3" cols="2" v-for="(item, idx) in members" :key=idx>
                  <member-card v-bind:id="item" v-bind:name="member_list[item].name"></member-card>
                </v-col>
              </v-row>
            </v-list>
            <v-list class="overflow-y-auto">
              <v-row class="input-comment ma-0 pa-0" align-content="end" dense>
                <v-col cols="6" ><v-text-field v-model="comment" label="コメント" maxlength="10" dense></v-text-field></v-col>
                <v-col cols="3"><v-btn class="ma-0 pa-1" outlined dense @click="send_comment">{{ comment_btn_lbl }}</v-btn></v-col>
                <v-col cols="3" v-if="exist_mute"><v-btn class="ma-0 pa-1" @click="clear_mute">非表示を解除</v-btn></v-col>
              </v-row>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </v-container>
</template>

<script>
  import expansionMixins from '@/Libs/mixins/expansionMixins.js'
  import MemberCard from '@/components/MemberCard.vue'

  export default {
    name: 'MemberView',
    mixins: [expansionMixins],
    components: {
      MemberCard,
    },
    data: () => ({
      unwatch: null,
      unwatch_mute: null,
      member_list: null,
      member_indexes: [],
      mute_flg: false,
      open: true,
      comment: '',
      open_panels: 0
    }),
    //
    computed: {
      members () {
//        console.log("Cmoputed: Members")
//        return this.member_list
        return this.member_indexes
      },
      exist_mute() {
        return this.mute_flg
      },
      comment_btn_lbl () {
        let lbl = 'コメント'
        const cmts = this.$store.getters['Meeting/getMemberComments']
        const uid = this.$store.getters['Meeting/getUserID']
        if((uid in cmts) && (cmts[uid].message != '')) {
          lbl = `あと${cmts[uid].sec}秒`
        }
        return lbl
      },
    },
    //
    mounted () {
      console.log('MemverView: MOUNTED')
      this.unwatch = this.$store.watch(
        (state, getters) => getters['Meeting/getMeetingState'], (newv) => {
          if(newv) {
            //
            const cuid = this.$store.getters['Meeting/getUserID']
            const mm = this.$store.getters['Meeting/getMuteMembers']
            this.member_list = {}
            this.member_indexes = []
            const okeys = Object.keys(newv)
            for(let uid of okeys) {
              if(!(mm.includes(uid))) {
                this.member_list[uid] = newv[uid]
                if(uid == cuid) {
                this.member_indexes.unshift(uid)
                } else {
                  this.member_indexes.push(uid)
                }
              }
            }
          }
        }
      )
      this.unwatch_mute = this.$store.watch(
        (state, getters) => getters['Meeting/getMuteMembers'], (newv) => {
          console.log(newv)
          if(newv.length != 0) {
            this.mute_flg = true
          } else {
            this.mute_flg = false
          }
        }
      )
    },
    //
    beforeDestory() {
      this.unwatch = null
      this.unwatch_mute = null
    },
    //
    methods: {
      clear_mute () {
        console.log('Clear Mute')
        this.$store.dispatch('Meeting/setMuteMembers', [])
      },
      send_comment() {
//        console.log(this.comment)
        let cmt = this.comment.trim()
        if(cmt == '') {
          console.log('empty message')
          return
        }

        // wheather sending or not
        const cmts = this.$store.getters['Meeting/getMemberComments']
        const uid = this.$store.getters['Meeting/getUserID']
        if((uid in cmts) && (cmts[uid].message != '')) {
          console.log("can't send message yet")
          return
        }

        // TODO: send message to  the server
        // FIXME: below line
        //cmt = cmt.slice(0, ks4c.MeetingInfo.MaxCommentLength)
        cmt = cmt.slice(0, 10)
        this.$store.dispatch('Meeting/setComment', cmt)
        this.comment = ''
      },
      toggle(){
        this.open = !this.open
      }

    },
  }
</script>

<style scoped>
.member-list {
  height: 20em;
}

.member-view {
  width: 90%;
}

.input-comment {
  height: 4em;
  width:90%
}
</style>