export default {
  data: () =>({
    ep_open: true
  }),
  computed:{
    accordion_icon () {
      if(this.ep_open) {
        return 'mdi-chevron-down'
      } else {
        return 'mdi-chevron-up'
      }
    }
  },
  methods: {
    ep_toggle(){
      this.ep_open = !this.ep_open
    }

  },
}