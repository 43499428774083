<template>
  <v-container>
    <v-row justify="center">
      <v-card>
        <v-card-title><v-img src="/images/ks4c-logo.png" width="400" contain></v-img></v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col><v-text-field v-model="meeting_id" label="会議ID" clearable></v-text-field></v-col>
            </v-row>
            <v-row>
              <v-col><v-text-field v-model="pass_phrase" label="パスフレーズ" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"  :type="show1 ? 'text' : 'password'" @click:append="show1 = !show1" clearable></v-text-field></v-col>
            </v-row>
            <v-row>
              <v-col><v-text-field v-model="name" label="表示名" clearable></v-text-field></v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-container>
            <v-row justify="center">
              <v-btn outlined style="font: bold;" @click="joinMeeting()">仮想会議室に参加する</v-btn>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-row>
    <!--
    <v-row><span></span></span></v-row>
    <v-row justify="center">
      <v-btn outlined>Windows 10版アプリをダウンロードする</v-btn>
    </v-row>
    -->
  </v-container>
</template>

<script>
export default {
  name: 'JionMeetingPanel',
  props: {
  },
  data: () => ({
    show1: false,
    meeting_id: '',
    pass_phrase: '',
    name: ''
  }),
  mounted () {
    const name = this.$ls.get('DISPLAY_NAME', '')
    if(name != '') {
      this.name = name
    }
  },
  computed: {
  },
  methods: {
    joinMeeting() {
      // check data
      if((this.meeting_id != '') && (this.pass_phrase !== '') &&
        (this.name != '')) {
        // set data
        this.$store.dispatch('Meeting/setMeetingID', this.meeting_id.toLowerCase())
        this.$store.dispatch('Meeting/setPassPhrase', this.pass_phrase)
        this.$store.dispatch('Meeting/setName', this.name)
        this.$ls.set('DISPLAY_NAME', this.name)

        // set Status
        this.$store.dispatch('Meeting/setJoinStatus', 1)
      } else {
        alert("参加に必要な情報がありません。")
        return
      }
    }
  }
}
</script>

<style>

</style>